<template>
  <div class="btn-login" style="width: 100vw; height: 100vh; margin-top: -50px; background-size: 100%; border-radius: 0px;">
    <div class="body-login">
      <div v-if="forICTDongnai" class="logo-class" style="padding-top: 30px">
      <img src="../../assets/icons/logo_ict_dn.png" style="margin-top: 20px; width: 100%; padding: 0 50px" />
      <!-- <img src="../../assets/icons/sub_logo.svg" style="margin-top: 30px;" /> -->
      <!-- <img src="../../assets/sidebar_icon/logo-without-text.svg" style="margin-top: 30px"/> -->
      </div>
      <div v-else style="padding-top: 70px; width: auto; display: flex; justify-content: space-around;">
        <img src="../../assets/sidebar_icon/Logo-text.svg" style="margin-top: 30px"/>
      </div>
      <div v-if="forICTDongnai" style="width: fit-content; margin: auto;">
        <h3 class="ICTMeetingRoomsWithoutPaperC1">
          {{ $t('checkLogin.ICTMeetingRoomsWithoutPaperC1') }}</h3>
        <h3 class="ICTMeetingRoomsWithoutPaperC2" style="margin-bottom: 30px;">
          {{ $t('checkLogin.meetingRoomsWithoutPaper') }}</h3>
      </div>
      <h3 v-else style="margin-top: 70px; font-weight: bold;">{{ $t('checkLogin.meetingRoomsWithoutPaper') }}</h3>
      <!-- Login form -->
      <div style="margin-top: 30px;">
        <span style="display: block; font-size: 14px; font-weight: bold;">{{ $t("checkLogin.loginMessage") }}</span>
        <form @submit="checkForm" style="width: 80%; margin: auto;">
          <div class="form-group">
            <input
              v-on:keyup.enter="checkForm"
              v-model="username"
              type="text"
              class="form-control mt-3"
              :placeholder="$t('checkLogin.loginPlaceholder')"
              aria-label="Username"
              aria-describedby="basic-addon1"
              v-bind:class="{ 'form-control': true, 'is-invalid' : !validEmail(username) && emailBlured}"
              v-on:blur="emailBlured = true"
            />
            <div class="invalid-feedback" style="text-align: left; color: #212529;">{{ errorText }}</div>
          </div>
          <button
            type="submit"
            class="btn-login btn mt-2"
            style="width: 100%; color: #ffffff;"
            v-on:click.stop.prevent="checkForm"
          >
            {{ $t("checkLogin.continue") }}
          </button>
        </form>
      </div>
    </div>
    <snack-bar
      v-for="snackbar in GET_SNACK_BAR"
      :key="snackbar.id"
      :item="snackbar"
    ></snack-bar>
    <loading-bar :isLoading="isLoading"></loading-bar>
    <confirm-modal
      ref="confirmModal"
      :content="confirmModalContent"
      @onConfirm="onConfirmGoToRegister()"
    ></confirm-modal>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import functionUtils from "utils/functionUtils";
import stringUtils from "utils/stringUtils";
import ConfirmModal from "Components/ConfirmModal";
import historyActionEnum from 'enum/historyLogEnum'

export default {
  components: {
    confirmModal: ConfirmModal
  },
  data: () => ({
    isLoading: false,
    username: "",
    emailBlured: false,
    password: "",
    locale: "vi",
    confirmModalContent: "checkLogin.notExistEmailInSystem",
    valid: false,
    forICTDongnai: process.env.VUE_APP_IS_FOR_DONGNAI === "true",
    errorText: ''
  }),
  computed: {
    ...mapGetters(["GET_SNACK_BAR"])
  },
  watch: {},
  mounted() {},
  methods: {
    validate: function () {
      this.emailBlured = true
      if(this.validEmail(this.username)){
        this.valid = true
      } else {
        this.valid = false
      }
    },
    /**
     * Check form
     */
    checkForm: function() {
      this.validate()
      if (this.valid == true) {
        this.onLogin()
      }
    },
    validEmail: function (email) {
      if (functionUtils.validateStringIsNull(email)) {
        this.errorText = this.$t('checkForm.invalidAccount');
        return false
      }
      // else if(!functionUtils.validateEmail(email) && !functionUtils.validatePhone(email)){
      //   this.errorText = this.$t('checkForm.emailIsNotValid');
      //   return false
      // }
      return true
    },
    /**
     * Change language
     */
    changeLanguage: function() {
      let locale = this.$store.state.language.selectedLocale;
      if (locale == "en") {
        this.$i18n.locale = "vi";
        this.locale = "vi";
      } else {
        this.$i18n.locale = "en";
        this.locale = "en";
      }
      this.$store.dispatch("changeLanguage", this.locale);
    },
    /**
     * On confirm go to register
     */
    onConfirmGoToRegister: function() {
      this.$router.push({
        name: "register",
        query: {
          email: this.username
        }
      });
    },
    /**
     * On login
     */
    onLogin: function() {
      if (this.username == "") {
        this.ON_SHOW_TOAST({
          message: this.$t("checkLogin.plsEnterAccount"),
          styleType: "danger"
        });
        return;
      } else {
        let filter = {
          username: this.username.toLowerCase(),
          password: this.password
        };
        this.isLoading = true;
        this.LOGIN(filter)
          .then(
            function() {
              this.isLoading = false;
              this.$router.push({
                name: "calendar.calendarMonth"
              });
              this.$store.state.app.inAMeeting = false;
              let data = {
                action_type: historyActionEnum.LOGIN_MEETING,
                description: this.$t('historyLog.descriptionText.loginMeeting')
              }
              this.createHistoryLog(data)
            }.bind(this)
          )
          .catch(
            function(error) {
              this.isLoading = false;
              if (error.message === stringUtils.NETWORK_ERROR) {
                this.ON_SHOW_TOAST({
                  message: this.$t("common.networkError"),
                  styleType: "danger"
                });
              } else if (!functionUtils.isNull(error.response.data.message)) {
                if (error.response.status === 404) {
                  if (error.response.data.code === 407) {
                    this.ON_SHOW_TOAST({
                      message: this.$t("checkLogin.phoneNumberDuplicate"),
                      styleType: "danger"
                    });
                  }
                  else {
                    if (functionUtils.validateEmail(this.username)) {
                      this.$refs.confirmModal.onShowModalConfirm();
                    } else {
                      this.ON_SHOW_TOAST({
                        message: this.$t("checkLogin.accountNoteExist"),
                        styleType: "danger"
                      });
                    }
                  }
                } else {
                  this.$router.push({
                    name: "login",
                    query: {
                      email: this.username
                    }
                  });
                }
              } else {
                this.ON_SHOW_TOAST({
                  message: this.$t("common.somethingWentWrong"),
                  styleType: "danger"
                });
              }
            }.bind(this)
          );
      }
    },
    createHistoryLog: function (data) {
      this.CREATE_HISTORY_LOG(data).then(
        function () {
        }.bind(this)
      )
    },
    ...mapActions(["ON_SHOW_TOAST", "LOGIN", "CREATE_HISTORY_LOG"])
  }
};
</script>
<style lang="scss" scoped>
@import "../../style/color.scss";
.login-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.btn-login {
  background: -moz-linear-gradient(bottom, $light-primary 100%, $primary 100%);
  background: -o-linear-gradient(bottom, $light-primary 100%, $primary 100%);
  background: -ms-linear-gradient(bottom, $light-primary 100%, $primary 100%);
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0.3, $light-primary),
    color-stop(0.7, $primary)
  );
  border-radius: 5px;
}

.logo-class {
  padding-top: 70px;
  width: auto;
  display: flex;
  justify-content: space-around;

  padding-top: 70px; width: auto; display: flex; justify-content: space-around;
}
.body-login{
  height: 498.88px;
  margin: auto;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  width: 394.64px;
  background-color: #ffffff;
}

.ICTMeetingRoomsWithoutPaperC1 {
  margin-top: 30px;
  font-weight: bold;
  text-transform: uppercase;
  color: #26ba50;
  // font-family: system-ui;
  // -webkit-text-stroke-width: 0.5px;
  // -webkit-text-stroke-color: #ffffff;
}
.ICTMeetingRoomsWithoutPaperC2 {
  font-weight: bold;
  text-transform: uppercase;
  white-space: nowrap;
}
</style>
